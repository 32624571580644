import { Avatar, Button, Card, CardBody, CardHeader, Image, Chip, Divider, Radio, RadioGroup, Tab, Tabs, CardFooter, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";
import SocialScienceIcon from "../components/Icons/SocialScienceIcon";
import DownGraphIcon from "../components/Icons/DownGraphIcon";
import UpArrowIcon from "../components/Icons/UpArrowIcon";
import DownArrowIcon from "../components/Icons/DownArrowIcon";
import FriendIndicatorIcon from "../components/Icons/FriendIndicatorIcon";
import DotIcon from "../components/Icons/DotIcon";
import OrganizationPeopleIcon from "../components/Icons/OrganizationPeopleIcon";
import { useEffect, useState } from "react";
import TopCategoryIcon from "../components/Icons/TopCategoryIcon";

interface HomePageProps {
    onSignupClicked: () => void,
    isLoggedIn: boolean
}

const HomePage = (props: HomePageProps) => {;
    const [liveCorrections, setLiveCorrections] = useState(true);
    const [ans, setAns] = useState<Number>(-1);
    const [correctAns, setCorrectAns] = useState<Number>(-1);
    const [justification, setJustification] = useState("");

    const [answerQuestionsModalOpen, setAnswerQuestionsModalOpen] = useState(false);
    
    const answerArr = ["a", "b", "c", "d", "e"];

    useEffect(() => {
        if (props.isLoggedIn){
            window.location.href = "/dashboard"
        }
    }, [])

    return ( 
        /*
        background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
        */

    <div style={{position:'absolute', top:'5rem', margin:'auto', justifyContent:'center', width:'100%'}}>
        <div style={{fontSize: 74, fontWeight:500, justifyContent:'center', margin:'auto', maxWidth:'fit-content'}}>
            <p style={{fontFamily:'GothamMedium'}}>Practice. Grow. Master.</p>
        </div>
        <div style={{fontSize: 32, fontWeight:300, justifyContent:'center', margin:'auto', textAlign:'center', maxWidth:'50%', marginBottom:'4rem'}}>
            <p>Level up your Academic Decathlon practice by answering thousands of questions, reviewing your progress, and competing with your peers.</p>
        </div>

        <div style={{justifyContent:'center', margin:'auto', maxWidth:'fit-content'}}>
            <Button radius="sm" size="lg" style={{backgroundColor:'#9000B3'}} onClick={props.onSignupClicked}>
                Sign Up For Free
            </Button>
        </div>
    </div>

    /*
        <>
        <div style={{marginRight:'1rem', marginLeft:'1rem', marginTop:'9rem', width:'50%', marginBottom:'-27rem'}}>
        <Tabs aria-label="Options" style={{flex:'center', justifyContent:'center', marginLeft:"7%", marginRight:'0rem'}}>
            <Tab key="questionLabel" title="Question">
                <Card style={{justifyContent:"center", width:"30rem", marginLeft:"42px", marginRight:"auto"}}>
                        <CardBody >
                        <p className="font-bold text-inherit text-balance" style={{textWrap:"pretty", marginRight:"auto", marginLeft:"auto", textAlign:"center"}}>Frank Stella is primarily known for his works in which of the following styles?</p>
                        </CardBody>
                    </Card>
            
            </Tab>
            { true && <Tab key="page" title="Section & Page">
            <Card style={{justifyContent:"start", width:"30rem", height:"auto", marginLeft:"42px", marginRight:"auto"}}>
                        <CardBody>
                        <p className="font-bold text-inherit text-balance" style={{textWrap:"pretty", marginRight:"auto", marginLeft:"auto", textAlign:"center"}}>Social Science Section 1. Page. 31 - 32</p>
                        </CardBody>
                    </Card>
            </Tab>}
            {
                liveCorrections && justification != "" && <Tab key="Justification" title="Justification">
                    <Card style={{justifyContent:"center", width:"30rem", height:"auto", marginLeft:"42px", marginRight:"auto"}}>
                        <CardBody>
                            <p className="font-bold text-inherit text-balance" style={{textWrap:"pretty", marginRight:"auto", marginLeft:"auto", textAlign:"center"}}>Frank Stella is best known for his large, entirely non-objective Minimalist paintings.</p>
                        </CardBody>
                    </Card>
                </Tab>
            }
            
        </Tabs>

        <p style={{position:'absolute', top:'170px', left:'150px', fontWeight:600}}>Try Answering A Question!</p>
        
            <div className="flex items-center h-3/6" style={{marginBottom:"auto", marginLeft:'3.9rem'}}>
            <RadioGroup id="radioGroup" value={ correctAns != -1 && liveCorrections ? (answerArr[parseInt(correctAns.toString())]) : ans != -1 ? answerArr[parseInt(ans.toString())] : ""}>
                <Radio value="a" color={(correctAns != -1 && liveCorrections && correctAns == 0) ? (correctAns == ans ? "success" : "danger") : "default"} onChange={() => {if (correctAns == -1) setAns(0)}} >A. Baroque painting</Radio>
                <Radio value="b" color={(correctAns != -1 && liveCorrections && correctAns == 1) ? (correctAns == ans ? "success" : "danger") : "default"} onChange={() => { if (correctAns == -1) setAns(1)}} >B. Minimalist non-objective paintings</Radio>
                <Radio value="c" color={(correctAns != -1 && liveCorrections && correctAns == 2) ? (correctAns == ans ? "success" : "danger") : "default"} onChange={() => { if (correctAns == -1) setAns(2)}} >C. Rococo sculpture</Radio>
                <Radio value="d" color={(correctAns != -1 && liveCorrections && correctAns == 3) ? (correctAns == ans ? "success" : "danger") : "default"} onChange={() => { if (correctAns == -1) setAns(3)}} >D. Gothic architecture</Radio>
                <Radio value="e" color={(correctAns != -1 && liveCorrections && correctAns == 4) ? (correctAns == ans ? "success" : "danger") : "default"} onChange={() => { if (correctAns == -1) setAns(4)}} >E. Surrealist photography</Radio>
            </RadioGroup>
            <Button color="secondary" style={{marginLeft:'2rem', marginTop:'6rem'}} onClick={() => {
                if (ans !== -1){
                    setCorrectAns(1);
                    setJustification("hi");
                }
            }}>
                Submit Answer
            </Button>
            </div>

            
        </div>
        <div style={{display:'grid', flex:'center', justifyContent:'center', margin:'auto', marginTop:'8rem', marginRight:'5rem', width:'44%', height:'10rem', backgroundColor:'#19242E', borderRadius:'9px'}}>
            <div>
                <p style={{fontSize:60}}>Bring your Ac-Dec prep to the next level with</p>
            </div>
            <div>
                <p style={{fontSize:100, fontWeight:600}}>HUGO ACDEC</p>
            </div>
        </div>

        <div style={{width:'90%', height:'25rem', borderRadius:'12px', marginTop:'16rem', backgroundColor:'#101010', justifyContent:'center', marginLeft:'4rem'}}>
            <p style={{fontSize:60, fontWeight:500, textAlign:'center', paddingTop:'3.5rem'}}>Answer unlimited questions and track your progress for completely free</p>
            <Button color="success" style={{color:'white', width:'20%', left:'37%', marginTop:'4rem', height:'3rem', fontSize:20, justifyContent:'center'}} onClick={props.onSignupClicked}>
                Sign up now
            </Button>
        </div>
        </>
        */
        

        
     );
}
 
export default HomePage;